<template>
  <div>
    <el-table
      :data="addFeeTotal(filterPaidTeachingLogs(teachingLogs))"
      style="width: 100%"
      :summary-method="getLessonLogSummaries"
      :show-summary="
        (isRoleAdmin() || isRoleTeacher()) && isPrivateLesson && showSalary
      "
    >
      <el-table-column label="Date" width="120">
        <template slot-scope="scope">
          <span v-if="getLessonLogStatus(scope.row.started_at)">
            {{ instant.formatDate(scope.row.started_at) }}
          </span>
          <span v-else class="text-warning"> Date not set</span>
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: `/${routePath}/${classId}/log/${scope.row.id}`
            }"
          >
            <span :class="getLessonLogStatus(scope.row.started_at)">
              {{ getLessonLogStatus(scope.row.started_at) }}
            </span>
          </router-link>
          <el-tooltip
            v-if="
              isPastLesson(scope.row.started_at) &&
                scope.row.description === null
            "
            class="item"
            popper-class="tooltipWrong"
            :content="$t('message.pleaseFinishedTheLessonog')"
            placement="top"
          >
            <span class="warning-icon">
              <i class="fas fa-exclamation" />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Teacher">
        <template>
          <a :href="`/user/${teacherUserId}`">
            {{ teacherUserName }}
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Students">
        <template slot-scope="scope">
          <span
            :key="lessonLog.id"
            v-for="(lessonLog, index) in scope.row.lesson_logs"
          >
            <a :href="`/user/${lessonLog.student_user_id}`">
              {{ lessonLog.student_user.name
              }}<span v-if="index !== scope.row.lesson_logs.length - 1">,</span>
            </a>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Hour" prop="full_hour" width="80" />
      <el-table-column
        label="Salary"
        v-if="
          (isRoleAdmin() || (isRoleTeacher() && this.showSalary)) &&
            isPrivateLesson
        "
      >
        <template slot-scope="scope">
          <div
            :class="
              getPaymentClass(
                isNeedToPay(scope.row.salary_is_paid, scope.row.salary)
              )
            "
          >
            <div>
              {{
                addCurrencySymbol(
                  convertPriceByCurrency(
                    Number(scope.row.full_hour) * Number(scope.row.salary),
                    scope.row.salary_currency,
                    teachingLogMainCurrency
                  ),
                  teachingLogMainCurrency
                )
              }}
            </div>
            <div
              v-if="scope.row.salary_currency !== teachingLogMainCurrency"
              class="originalPrice"
            >
              ≈{{
                addCurrencySymbol(
                  Number(scope.row.full_hour) * Number(scope.row.salary),
                  scope.row.salary_currency
                )
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Tuition" v-if="isRoleAdmin() && isPrivateLesson">
        <template slot-scope="scope">
          {{ addCurrencySymbol(scope.row.feeTotal, scope.row.feeCurrency) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="Actions">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="
                `/${routePath}/${classId}/editLog/${scope.row.lesson_logs[0].id}`
              "
            >
              <el-button class="mr-2" type="primary" size="mini">
                <i class="el-icon-edit-outline" />
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            v-if="isRoleAdmin()"
            v-show="isNeedToPay(scope.row.salary_is_paid, scope.row.salary)"
            class="item"
            content="Mark salary paid"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link :to="{ name: 'Salary' }">
              <el-button class="mr-2" type="primary" size="mini">
                <i class="fas fa-sign-out-alt action-icon" />
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Delete"
            placement="top"
            popper-class="tooltipColor"
          >
            <DeleteIconButton :onDelete="deleteTeachingLog(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <MultipleCurrencyTotalPrompt v-if="isTeachingLogsCurrencyMultiple" />
  </div>
</template>

<script>
import moment from "moment";
import { instant } from "@ivy-way/material";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import lessonLogApi from "@/apis/lessonLog";

export default {
  props: {
    teachingLogs: {
      type: Array,
      default: () => []
    },
    showSalary: {
      type: Boolean,
      default: true
    },
    teacherUserId: {
      type: Number,
      default: null
    },
    teacherUserName: {
      type: String,
      default: ""
    },
    filterPaid: {
      type: Boolean,
      default: false
    },
    isPrivateLesson: {
      type: Boolean,
      default: true
    },
    privateType: {
      type: String,
      default: "privateLesson"
    },
    classId: {
      type: Number,
      default: null
    }
  },
  computed: {
    instant() {
      return instant;
    },
    routePath() {
      // if (!this.isPrivateLesson) return "session-class";
      // if (!this.isPrivateLesson) return "private/counseling";
      // if (this.privateType === "counseling") {
      //   return "private/counseling";
      // }
      // return "private/private-lessons";
      return "private/private-lessons";
    },
    isTeachingLogsCurrencyMultiple() {
      const lessonLogCurrencys = [];
      this.teachingLogs.forEach(teachingLog => {
        teachingLog.lesson_logs.forEach(lessonLog => {
          lessonLogCurrencys.push(lessonLog.fee_currency);
        });
      });
      return (
        this.isCurrencyMultiple(this.teachingLogsCurrency) ||
        this.isCurrencyMultiple(lessonLogCurrencys)
      );
    },
    teachingLogsCurrency() {
      return this.teachingLogs.map(teachingLog => teachingLog.salary_currency);
    },
    teachingLogMainCurrency() {
      return this.getMainCurrency(this.teachingLogsCurrency);
    }
  },
  components: {
    MultipleCurrencyTotalPrompt,
    DeleteIconButton
  },
  mixins: [moneyMixin, roleMixin],
  async created() {
    await this.initialExchangeRate();
  },
  methods: {
    isPastLesson(date) {
      return moment(date).isBefore(moment());
    },
    getLessonLogStatus(startDate) {
      switch (true) {
        case moment(new Date()) === moment(startDate):
          return "Ongoing";
        case moment(new Date()) > moment(startDate):
          return "Completed";
        case moment(new Date()) < moment(startDate):
          return "Scheduled";
      }
    },
    filterPaidTeachingLogs(teachingLogs) {
      if (this.filterPaid) {
        return teachingLogs.filter(teachingLog => !teachingLog.salary_is_paid);
      }
      return teachingLogs;
    },
    getPaymentClass(flag) {
      return flag ? "needToPay" : "";
    },
    isNeedToPay(status, price) {
      return !(status || Number(price) === 0);
    },
    addFeeTotal(teachings) {
      return teachings.map(teaching => ({
        ...teaching,
        feeTotal: this.getFeeTotal(teaching.lesson_logs, teaching.full_hour),
        feeCurrency: this.getMainCurrency(
          teaching.lesson_logs.map(({ fee_currency }) => fee_currency)
        )
      }));
    },
    getFeeTotal(lessonLogs, teachingHours) {
      const feeWithCurrencys = lessonLogs.map(({ fee, fee_currency }) => ({
        price: Number(fee) * teachingHours,
        currency: fee_currency
      }));
      if (feeWithCurrencys.length === 0) return 0;
      return this.calculationPriceWithCurrency(feeWithCurrencys);
    },
    getLessonLogSummaries({ columns, data: teachingLogs }) {
      const sums = [];
      if (teachingLogs.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = "Total";

        if (index === 4) {
          const hours = teachingLogs.map(teachingLog => teachingLog.full_hour);
          columnsText = hours.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        }
        if (index === 5) {
          columnsText = this.addCurrencySymbol(
            this.calculationPriceWithCurrency(
              teachingLogs.map(({ salary, salary_currency, full_hour }) => ({
                price: salary * full_hour,
                currency: salary_currency
              }))
            ),
            this.getMainCurrency(
              teachingLogs.map(({ salary_currency }) => salary_currency)
            )
          );
        }
        if (index === 6 && this.isRoleAdmin()) {
          columnsText = this.addCurrencySymbol(
            this.calculationPriceWithCurrency(
              teachingLogs.map(({ feeTotal, feeCurrency }) => ({
                price: feeTotal,
                currency: feeCurrency
              }))
            ),
            this.getMainCurrency(
              teachingLogs.map(({ feeCurrency }) => feeCurrency)
            )
          );
        }

        sums[index] = columnsText;
      });
      return sums;
    },
    deleteTeachingLog(teachingId) {
      return async () => {
        const response = await lessonLogApi.deleteTeachingLog(teachingId);

        this.$emit("reloadCustomLesson");

        if (response.need_update_order_ids != null) {
          for (let i = 0; i < response.need_update_order_ids.length; i++) {
            let message =
              "連動一筆帳單金額，請再確認，帳單ID: <a target='_blank' href='/bill/" +
              response.need_update_order_ids[i] +
              "'>" +
              response.need_update_order_ids[i] +
              "</a>";
            this.$message({
              showClose: true,
              message: message,
              type: "warning",
              dangerouslyUseHTMLString: true
            });
          }
        }
      };
    }
  }
};
</script>

<style scoped>
.Scheduled {
  color: orange;
}

.originalPrice {
  margin-left: 24px;
  font-size: 12px;
  color: #999999;
}

.needToPay {
  background: red;
  color: #fff;
}

.needToPay .originalPrice {
  color: #fff;
}
</style>
