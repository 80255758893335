<template>
  <div>
    <el-table
      :data="filterPaidLessonLogs(lessonLogs)"
      style="width: 100%"
      :summary-method="getLesssonLogSummaries"
      :show-summary="isRoleAdmin() && isPrivateLesson"
    >
      <el-table-column label="Date">
        <template slot-scope="scope">
          <!-- {{ instant.formatGoogleTime(scope.row.started_at) }} -->
          <span v-if="getLessonLogStatus(scope.row.started_at)">
            {{ instant.formatGoogleTime(scope.row.started_at) }}
          </span>
          <span v-else class="text-warning"> Date not set</span>
        </template>
      </el-table-column>
      <el-table-column label="Status">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: `/${routePath}/${classId}/log/${scope.row.id}/student/${scope.row.student_user_id}`
            }"
          >
            <span :class="getLessonLogStatus(scope.row.started_at)">
              {{ getLessonLogStatus(scope.row.started_at) }}
            </span>
          </router-link>
          <el-tooltip
            v-if="
              (isRoleAdmin() || isRoleTeacher()) &&
                isPastLesson(scope.row.started_at) &&
                scope.row.teaching_log.description === null
            "
            class="item"
            popper-class="tooltipWrong"
            :content="$t('message.pleaseFinishedTheLessonog')"
            placement="top"
          >
            <span class="warning-icon">
              <i class="fas fa-exclamation" />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Teacher">
        <template slot-scope="scope">
          {{
            scope.row.teaching_log.teacher_user
              ? scope.row.teaching_log.teacher_user.name
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="Student">
        <template slot-scope="scope">
          <AttendanceStatusLight
            class="attendanceStatusLight"
            :attendanceStatus="scope.row.attendance_status"
          />
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: scope.row.student_user_id }
            }"
          >
            {{ scope.row.student_user.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Hour" prop="teaching_log.full_hour" />
      <el-table-column
        label="Tuition"
        v-if="(isRoleAdmin() || isRoleParent()) && isPrivateLesson"
      >
        <template slot-scope="scope">
          <div
            :class="
              getPaymentClass(
                isNeedToPay(scope.row.payment_status, scope.row.fee)
              )
            "
          >
            <div>
              {{
                addCurrencySymbol(
                  convertPriceByCurrency(
                    Number(scope.row.teaching_log.full_hour) *
                      Number(scope.row.fee),
                    scope.row.fee_currency,
                    lessonLogMainCurrency
                  ),
                  lessonLogMainCurrency
                )
              }}
            </div>
            <div
              v-if="scope.row.fee_currency !== lessonLogMainCurrency"
              class="originalPrice"
            >
              ≈{{
                addCurrencySymbol(
                  Number(scope.row.teaching_log.full_hour) *
                    Number(scope.row.fee),
                  scope.row.fee_currency
                )
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Actions" v-if="isRoleAdmin()">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Edit"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="`/${routePath}/${classId}/editLog/${scope.row.id}`"
            >
              <el-button class="mr-2" type="primary" size="mini">
                <i class="el-icon-edit-outline" />
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Mark tuition paid"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :style="
                `visibility: ${
                  isNeedToPay(scope.row.payment_status, scope.row.fee)
                    ? 'visible'
                    : 'hidden'
                }`
              "
              :to="{
                path: `/bill/create/lessonlog/${enrollSessionId}/${classId}/${scope.row.id}`
              }"
            >
              <el-button class="mr-2" type="primary" size="mini">
                <i class="fas fa-sign-in-alt" />
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            content="Delete"
            placement="top"
            popper-class="tooltipColor"
          >
            <DeleteIconButton :onDelete="deleteLessonLog(scope.row.id)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <MultipleCurrencyTotalPrompt v-if="isLessonLogCurrencyMultiple" />
  </div>
</template>

<script>
import moment from "moment";
import { instant } from "@ivy-way/material";
import MultipleCurrencyTotalPrompt from "@/components/prompt/MultipleCurrencyTotalPrompt";
import AttendanceStatusLight from "@/components/privateLesson/AttendanceStatusLight";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import lessonLogApi from "@/apis/lessonLog";

export default {
  props: {
    lessonLogs: {
      type: Array,
      default: () => []
    },
    enrollSessionId: {
      type: Number,
      default: null
    },
    filterPaid: {
      type: Boolean,
      default: false
    },
    isPrivateLesson: {
      type: Boolean,
      default: true
    },
    privateType: {
      type: String,
      default: "privateLesson"
    },
    classId: {
      type: Number,
      default: null
    }
  },
  components: {
    MultipleCurrencyTotalPrompt,
    AttendanceStatusLight,
    DeleteIconButton
  },
  computed: {
    instant() {
      return instant;
    },
    routePath() {
      // if (!this.isPrivateLesson) return "session-class";
      // if (!this.isPrivateLesson) return "private/counseling";
      // if (this.privateType === "counseling") {
      //   return "private/counseling";
      // }
      return "private/private-lessons";
    },
    lessonLogsCurrency() {
      return this.lessonLogs.map(({ fee_currency }) => fee_currency);
    },
    lessonLogMainCurrency() {
      return this.getMainCurrency(this.lessonLogsCurrency);
    },
    isLessonLogCurrencyMultiple() {
      return this.isCurrencyMultiple(this.lessonLogsCurrency);
    }
  },
  mixins: [moneyMixin, roleMixin],
  async created() {
    await this.initialExchangeRate();
  },
  methods: {
    isPastLesson(date) {
      return moment(date).isBefore(moment());
    },
    filterPaidLessonLogs(lessonLogs) {
      if (this.filterPaid) {
        return lessonLogs.filter(lessonLog => !lessonLog.payment_status);
      }
      return lessonLogs;
    },
    getLessonLogStatus(startDate) {
      switch (true) {
        case moment(new Date()) === moment(startDate):
          return "Ongoing";
        case moment(new Date()) > moment(startDate):
          return "Completed";
        case moment(new Date()) < moment(startDate):
          return "Scheduled";
      }
    },
    filterUnpaid(lessonLogs) {
      if (!this.filterPaid) return lessonLogs;

      if (this.$store.getters["user/isRole"]("super-admin")) {
        return lessonLogs.filter(
          lessonLog =>
            !lessonLog.payment_status || !lessonLog.teaching_log.salary_is_paid
        );
      } else if (this.$store.getters["user/isRole"]("teacher")) {
        return lessonLogs.filter(
          lessonLog => !lessonLog.teaching_log.salary_is_paid
        );
      } else if (this.$store.getters["user/isRole"]("parent")) {
        return lessonLogs.filter(lessonLog => !lessonLog.payment_status);
      }
      return [];
    },
    getPaymentClass(flag) {
      return flag ? "needToPay" : "";
    },
    isNeedToPay(status, price) {
      return !(status || Number(price) === 0);
    },
    getLesssonLogSummaries({ columns, data: lessonLogs }) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const sums = [];
      if (lessonLogs.length === 0) return sums;
      columns.forEach((column, index) => {
        let columnsText = "";
        if (index === 0) columnsText = "Total";

        if (index === 4) {
          const hours = lessonLogs.map(
            lessonLog => lessonLog.teaching_log.full_hour
          );
          columnsText = hours.reduce(reducer);
        }

        if (index === 5) {
          const currencys = lessonLogs.map(({ fee_currency }) => fee_currency);
          columnsText = this.addCurrencySymbol(
            this.calculationPriceWithCurrency(
              lessonLogs.map(({ fee, fee_currency, teaching_log }) => ({
                price: Number(fee) * teaching_log.full_hour,
                currency: fee_currency
              }))
            ),
            this.lessonLogMainCurrency
          );
        }

        sums[index] = columnsText;
      });
      return sums;
    },
    deleteLessonLog(lessonLogId) {
      return async () => {
        await lessonLogApi.deleteLessonLog(lessonLogId);
        this.$emit("reloadCustomLesson");
      };
    }
  }
};
</script>

<style scoped>
.Scheduled {
  color: orange;
}

.originalPrice {
  margin-left: 24px;
  font-size: 12px;
  color: #999999;
}

.needToPay {
  background: red;
  color: #fff;
}

.needToPay .originalPrice {
  color: #fff;
}

.attendanceStatusLight {
  margin-right: 4px;
}
</style>
